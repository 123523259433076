import React, { Component } from "react"
import { graphql } from "gatsby"

//components
import IndexLayout from "../components/IndexLayout"
import SEO from "../components/seo"
import Helmet from "react-helmet"

//utilities
import styled from "styled-components"
import { rhythm } from "../utils/typography"

const SuccessMessageContainerStyled = styled.section`
  background-color: ${({ theme }) => theme.contactSuccessColor};
  border-radius: 5px;
  box-shadow: ${({ theme }) => theme.baseBoxShadow};
  text-align: center;
  padding: ${rhythm(1 / 4)};
`

class ContactSuccess extends Component {
  render() {
    const { data } = this.props
    const siteTitle = data.site.siteMetadata.title
    return (
      <IndexLayout location={this.props.location} title={siteTitle}>
        <Helmet>
          <meta name="robots" content="noindex" />
        </Helmet>
        <SEO title="Message envoyé !" />
        <SuccessMessageContainerStyled>
          <h2>Merci !</h2>
          <p>Votre message m’a bien été envoyé.</p>
          <p>Je vous réponds dès que possible et au maximum en 48h ;-).</p>
        </SuccessMessageContainerStyled>
      </IndexLayout>
    )
  }
}

export default ContactSuccess

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`
